import React from 'react';
import PageHeader from '../components/reusable/PageHeader';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import SocialIcons from '../components/footer/SocialIcons';
import { colors } from '../constants/styles';
import { device } from '../constants/mediaQueries';
import { Helmet } from 'react-helmet';
import ContactForm from '../components/forms/ContactForm';

if (typeof window !== 'undefined') {
  window.initMap = function() {
    var mapLat = 49.069955;
    var mapLng = 33.410892;
    var mapZoom = parseInt("15", 10);
    var mapWheel = "1";
    if (mapWheel == '1'){
      mapWheel = true;
    } else {
      mapWheel = false;
    }
    // Create a map object and specify the DOM element for display.
    var map = new window.google.maps.Map(document.getElementById('map'), {
      center: {lat: mapLat, lng: mapLng},
      scrollwheel: mapWheel,
      zoom: mapZoom
    });

    var marker = new window.google.maps.Marker({
          position: {lat: mapLat, lng: mapLng},
          map: map
        });

    window.onresize = function(event) {
      var timeoutID = window.setTimeout(function(){
        window.google.maps.event.trigger(map, "resize");
      }, 300);
    };
  }
}

const ContactDataStyle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${device.deviceS}{
    flex-direction: row;
    justify-content: space-around;
  }

  > div {
    margin-bottom: 2.5rem;

    p {
      margin-bottom: 0.5rem;
    }

    a {
      font-weight: 500;
      font-size: 1rem;
      text-decoration: underline;
      color: ${colors.orange};

      &.tel {
        color: inherit;
        font-weight: 700;
      }
    }
  }

  .col-title {
    margin-bottom: 1.5rem;

    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.4rem;
    }

    h3 {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      font-size: 1rem;
    }
  }
`;

const MapAndFormStyle = styled.div`
    position: relative;
    min-height: 480px;
    background: #f2f2f2;
    margin-bottom: 30px;
    

    .map-cont-wrap {
      position: relative;
      min-height: 480px;
      height: 100%;
      z-index: 1;
      width: 100%;
    }

   #map {
      width: 100%;
      overflow: hidden;position: static;
      min-height: 350px;
      height: 350px;
      overflow: hidden;
      position: relative;
      

      @media ${device.deviceS}{
        min-height: 480px;
        height: 100%;
        z-index: 2;
        position: absolute!important;
        top: 0;
        right: 0;
        width: 50%;
      }
   }

   .contact-form {
     width: 100%;
     @media ${device.deviceS}{
       width: 48%;
       margin-right: 2%;
     }
   }
`;

const Contact = () => {
  const icons = useStaticQuery(graphql`
    query {
      location: allFile(filter: {name: {eq: "location"}, extension: {eq: "png"}}) {
        edges {
          node {
            publicURL
          }
        }
      }
      phone: allFile(filter: {name: {eq: "phone"}, extension: {eq: "png"}}) {
        edges {
          node {
            publicURL
          }
        }
      }
      mail: allFile(filter: {name: {eq: "mail"}, extension: {eq: "png"}}) {
        edges {
          node {
            publicURL
          }
        }
      }
     }
  `);
  return (
  <Layout>
    <SEO title='Contact Us' />
    <Helmet>
      <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCgXefCO5bi-QqiyNMX2AF1enkh-DCsjWo&amp;callback=initMap" async defer type="text/javascript" />
    </Helmet>
    <PageHeader title="Contact Us" />
    <div className="container">
      <ContactDataStyle>
        <div>
          <div className="col-title">
            <img src={icons.location.edges[0].node.publicURL} alt="Location" className="contact-icon" />
            <h3>Adress</h3>
          </div>
          <p>Nebesnoy Sotni, 24/37</p>
          <p>office 1516</p>
          <p>Kremenchuk</p>
          <p>Ukraine, 39600</p>
        </div>
        <div>
          <div className="col-title">
            <img src={icons.phone.edges[0].node.publicURL} alt="Phone" className="contact-icon" />
            <h3>Call us</h3>
          </div>
          <p>United Kingdom (toll free)</p>
          <p><a className="tel" href="tel:+448000988650">+44 800 098 86 50&nbsp;</a></p>
          <p>United States</p>
          <p><a className="tel" href="tel:+16466612998">+1 646 661 29 98</a></p>
        </div>
        <div>
          <div className="col-title">
            <img src={icons.mail.edges[0].node.publicURL} alt="Mail" className="contact-icon" />
            <h3>Email us</h3>
          </div>
          <p><a href="mailto:HI@optimozg.com">hi@optimozg.com</a></p>
          <SocialIcons />
        </div>
      </ContactDataStyle>
    </div>
    <MapAndFormStyle>
      <div className="map-cont-wrap">
        <div className="container">
          <div className="contact-form">
            <ContactForm />
          </div>
        </div>
        <div id="map">map</div>
      </div>
    </MapAndFormStyle>
    
  </Layout>
)}

export default Contact

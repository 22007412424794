import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';
import FormButton from './FormButton';

const FormWrap = styled.div`

h3 {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media ${device.deviceM}{
        margin-top: 3rem;
    }
}

text-align: center;
padding-top: 1px;

.inputs {
    display: flex;
    flex-direction: column;
    
    input, textarea {
        border: none;
        background: ${colors.white};
        font-weight: 300;
        font-size: 1.2rem;
        border: none;
        padding: 0.7rem 1rem 0.5rem;
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

}

button[type="submit"] {
    margin-bottom: 1rem;

    @media ${device.deviceS}{
        margin-bottom: 0;
        position: relative;
        top: 1rem;
    }
}
`;

const ContactForm = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');

    return (
        <section>
            <div className="container">
                <FormWrap>
                    <h3>Contact us</h3>
                    <form method="post" name="Contact Form" data-netlify="true" action="/thank-you" data-netlify-honeypot="bot-field">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="Contact Form" />
                        <div className="inputs">
                            <input
                                type="text"
                                name="name"
                                onChange={(event) => setName(event.target.value)}
                                size="40"
                                value={name}
                                placeholder="Name" />
                            <input 
                                type="email" 
                                name="email" 
                                onChange={(event) => setEmail(event.target.value)}
                                size="40" 
                                value={email} 
                                placeholder="Email" />
                            <textarea
                                type="text"
                                name="question"
                                onChange={(event) => setQuestion(event.target.value)}
                                cols="40"
                                rows="5"
                                placeholder="Question"
                                value={question}>
                            </textarea>
                        </div>
                        <FormButton />
                    </form>
                </FormWrap>
            </div>
        </section>
    );
}

export default ContactForm;